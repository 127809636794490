html,
body {
  font-size: 14px;
}
body {
  font-family: sans-serif;
  overflow: hidden;
  font-family: "Share Tech", sans-serif;
  margin: 0;
  text-shadow: 0px 0px 16px #1c1d1e;
}

#app {
  height: 100vh;
  overflow: hidden;
  cursor: grab;
}
#app:active {
  cursor: grabbing;
}
canvas {
  width: 100%;
  height: 100%;
}

.controls {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  user-select: none;
  pointer-events: none;
  text-align: center;
}

h4 {
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.3rem;
  padding-bottom: 0.5rem;
}
h2 {
}
h4,
h2,
h5 {
  margin: 0 0;
  font-weight: normal;
  color: white;
}
.count-title {
  position: relative;
}
.icon {
  color: white;
  font-size: 3rem;
  text-shadow: 0px 0px 16px #1c1d1e;
}

button {
  user-select: auto;
  pointer-events: all;
}
h4,
h3,
h2 {
  user-select: text;
  pointer-events: all;
}
.more {
  /* font-size: 2.4rem; */
}
#count {
  font-size: 5rem;
  font-weight: 200;
  line-height: 0.9em;
}
.buttons {
  display: flex;
}
button {
  margin: 0;
  padding: 0rem 0rem;
  background: none;
  color: white;
  border: none;
}
.flip {
  transform: scaleX(-1);
}
button:hover {
  cursor: pointer;
  background: transparent;
}
button:hover .icon {
  color: #747484;
}
#less {
  margin-right: 1rem;
}
.more {
  left: 100%;
}

.switch {
  font-size: 1rem;
  margin: 0;
  padding: 0.5rem;
  padding-bottom: 0;
  border: none;
  border: none;
  text-decoration: underline;
  font-family: "Share Tech", sans-serif;
}
.switch:hover {
  cursor: pointer;
  color: #9494aa;
  background: transparent;
}

@media screen and (min-width: 400px) {
  body,
  html {
    font-size: 14px;
  }
}
@media (orientation: landscape) and (max-height: 550px) {
  /* landscape styles */
  .controls {
    width: auto;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin-left: 4rem;
    bottom: 0;
  }
}
